<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </template>
        <v-date-picker v-model="date" scrollable @input="modal = false; $refs.dialog.save(date); search();">
        </v-date-picker>
      </v-dialog>
      <v-toolbar-title :class="$vuetify.breakpoint.xs ? 'caption' : ''">
        <span>{{ date }}</span>
        <span :class="$vuetify.breakpoint.xs ? 'ml-2' : 'ml-5'">Активных : {{ active }}</span>
        <span :class="$vuetify.breakpoint.xs ? 'ml-2' : 'ml-5'">Отключенных : {{ finished }}</span>
      </v-toolbar-title>
    </v-toolbar>

    <v-row dense class="mt-2 hidden-xs-only">
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Статус</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-start">
        <span class="text-caption pl-6">Курьер</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">Телефон</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption pr-16">Кол-во заказов</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption pr-4">Фото</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption pr-4">Транспорт</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption pr-4">Начало / Конец</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption pr-6">Статус</span>
      </v-col>
    </v-row>
    <v-row no-gutters v-for="(driver, i) in list" :key="driver.id" class="grey lighten-4 mb-1 py-1">
      <v-col v-if="driver.shifts.length > 0" cols="12"
             class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
        <v-chip v-if="driver.shifts[0].status === 'ACTIVE'" color="rgba(63, 196, 79, 1)" dark>
          {{ statusReason(driver.shifts[0]) }}
        </v-chip>
        <v-chip v-else color="rgba(135,135,135,1)" dark>{{ statusReason(driver.shifts[0]) }}</v-chip>
      </v-col>
      <v-col cols="12"
             class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-center justify-lg-start justify-md-start justify-sm-start align-center">
        <span class="text-body-2">{{ i + 1 }} : {{ driver.name }}</span>
      </v-col>
      <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-center align-center">
        <span class="text-body-2">{{ driver.phone | normalizePhone }}</span>
      </v-col>
      <v-col cols="12"
             class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-center justify-lg-start justify-md-start justify-sm-start align-center">
        <span class="caption">Всего:</span>
        <span class="font-weight-bold text-body-2">{{ driver.total_count }}</span>
        <span class="px-1 font-weight-bold">/</span>
        <span class="caption">Сегодня:</span>
        <span class="font-weight-bold text-body-2">{{ driver.count }}</span>
      </v-col>
      <v-col cols="12" class="col col-lg-5 col-md-5 col-sm-5 row no-gutters d-flex align-center">
        <v-row v-for="shift in driver.shifts" :key="shift.id">
          <v-col cols="6"
                 class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-center justify-lg-end justify-md-end justify-sm-end d-flex align-center">
            <v-avatar v-if="shift.photo" size="48px">
              <img :src="shift.photo" alt="">
            </v-avatar>
            <v-avatar v-else size="48px">
              <img src="/user_avatar.png" alt="">
            </v-avatar>
          </v-col>
          <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-center d-flex align-center">
            <span class="text-body-2">{{ shift.vehicle }}</span>
          </v-col>
          <v-col cols="6" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-center d-flex align-center">
            <span class="text-body-2">{{ shift.created_at | timeFormat }}</span>
            <span class="text-body-2">{{ shift.ended_at | timeFormat }}</span>
          </v-col>
          <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-center d-flex align-center">
            <v-chip
                v-if="shift.status === 'FINISHED' && (shift.finished_reason === 'END_OF_SHIFT')"
                color="rgba(135,135,135,1)" dark>
              {{ statusReason(shift) }}
            </v-chip>
            <v-chip v-else-if="shift.status === 'ACTIVE'" color="rgba(63, 196, 79, 1)" dark close
                    @click:close="remove(shift)">
              {{ statusReason(shift) }}
            </v-chip>
            <v-chip v-else-if="shift.finished_reason === 'LUNCH'" color="orange" dark close
                    @click:close="remove(shift)">
              {{ statusReason(shift) }}
            </v-chip>
            <v-chip v-else-if="shift.finished_reason === 'FORCE_MAJEURE'" color="red" dark close
                    @click:close="remove(shift)">
              {{ statusReason(shift) }}
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import axios from "@/services/axios";
import format from 'date-fns/format';

export default {
  data: () => ({
    list: [],
    hidden: true,
    date: new Date().toISOString().substr(0, 10),
    modal: false,
    dialog: false,
    driver: undefined
  }),

  created() {
    this.search();
  },

  computed: {
    active() {
      return this.list.filter(driver => driver.shifts.length > 0 && driver.shifts[0].status !== 'FINISHED').length;
    },
    finished() {
      return this.list.filter(driver => driver.shifts.length > 0 && driver.shifts[0].status === 'FINISHED').length;
    }
  },

  methods: {
    search() {
      axios.send({
        url: "/status/drivers?date=" + this.date,
        method: "GET",
      }).then((resp) => {
        this.list = resp.data;
      });
    },
    remove(shift) {
      axios.send({
        url: "/status/drivers/" + shift.id,
        method: "DELETE",
      }).then(() => {
        this.search();
      });
    },
    status(val) {
      return val.toLowerCase();
    },
    statusReason(shift) {
      if (shift.status === 'ACTIVE') {
        return 'На смене'
      }
      if (shift.status === 'FINISHED' && shift.finished_reason === 'LUNCH') {
        return 'На обеде'
      }
      if (shift.status === 'FINISHED' && shift.finished_reason === 'FORCE_MAJEURE') {
        return 'Форс-мажор'
      }

      return 'Завершил смену'
    }
  },
  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",");

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
    timeFormat(d) {
      if (d) {
        return format(new Date(d), "yyyy-MM-dd HH:mm")
      }
      return ""
    },
  }
}
</script>
